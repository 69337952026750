<script setup lang="ts">
import Icon from '~/components/global/Icon.vue'
import useFavoritesStore from '~/stores/useFavoritesStore'
import type { EstateDetailInterface } from '~/types/api/EstateDetail'
import type { EstateListing } from '~/types/api/EstateListing'

const props = defineProps<{
  estateData: EstateListing | EstateDetailInterface
  detailHeader?: boolean
}>()

const favoritesStore = useFavoritesStore()
const isInFavorites = computed(() => {
  if (!favoritesStore.favoriteItems) return false
  return favoritesStore.favoriteItems.includes(props.estateData.id.toString())
})
const isLoading = computed(() => {
  return !!favoritesStore.pendingItemsIds[props.estateData.id]
})
</script>

<template>
  <div class="c-estate-favorite-trigger">
    <IButton
      :class="[
        'c-estate-favorite-trigger__button',
        detailHeader && 'c-estate-favorite-trigger__button--detail-header',
        !detailHeader && 'c-estate-favorite-trigger__button--default',
        isInFavorites && 'is-in-favorites',
      ]"
      :loading="isLoading"
      :color="detailHeader ? 'primary' : undefined"
      @click="favoritesStore.toggle(estateData)"
    >
      <Icon :name="isInFavorites ? 'heart-full' : 'heart'" />
    </IButton>
  </div>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

.c-estate-favorite-trigger {
  --estate-favorite-trigger--background-opacity: 0;

  &__button {
    &--default {
      font-size: 14px;
      width: 32px;
      height: 32px;
      padding: 0;
      display: grid;
      place-items: center;
      min-height: 0;
      background-color: hsla(
        var(--color-dark-h),
        var(--color-dark-s),
        var(--color-dark-l),
        var(--estate-favorite-trigger--background-opacity)
      );
      color: var(--color-light);
      border-radius: 50px;

      &:hover,
      &:focus {
        background-color: hsla(
          var(--color-dark-h),
          var(--color-dark-s),
          var(--color-dark-l),
          var(--estate-favorite-trigger--background-opacity)
        );
        color: var(--color-light);

        --estate-favorite-trigger--background-opacity: 1;
      }
    }

    &.-loading {
      --button--disabled--opacity: 1;
      --button--color: var(--color-light);

      background-color: var(--color-dark);
      color: var(--color-light);
    }
  }
}
</style>
